import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Reimbursements from '../views/Reimbursements.vue'
import Signin from '../views/Signin.vue'
import Signup from '../views/Signup.vue'
import { auth } from '../firebase'
import Team from '../views/Team.vue'
import Account from '../views/Account.vue'
import Apply from '../views/Apply.vue'
import Teammember from '../views/Teammember'
import Kudos from '../views/Kudos'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reimbursements',
    name: 'Reimbursements',
    component: Reimbursements,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: {
      noNav: true,
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      noNav: true,
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/team/:id',
    name: 'Team member',
    component: Teammember,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/apply',
    name: 'Apply',
    component: Apply,
    meta: {
      noNav: true,
    }
  },
  {
    path: '/kudos',
    name: 'Kudos',
    component: Kudos,
    meta: {
      requiresAuth: true,
    }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/signin')
  } else {
    next()
  }
})

export default router
