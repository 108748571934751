<template>
    <div>
        <p><b>Home address</b></p>
        <v-row>
            <v-col
            cols="12"
            md="4"
            >
            <v-text-field
                v-model="user.address"
                label="Address"
                required
            ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="3"
            >
            <v-text-field
                v-model="user.postal"
                label="Postal code"
                required
            ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="2"
            >
            <v-text-field
                v-model="user.city"
                label="City"
                required
            ></v-text-field>
            </v-col>
        </v-row>

        <p><b>Birthday</b></p>
        <v-row>
            <v-col
            cols="12"
            md="4"
            >
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="user.birthday"
                    label="Birthday date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                ref="picker"
                v-model="user.birthday"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="save"
                ></v-date-picker>
            </v-menu>
            </v-col>
        </v-row>

        <p><b>In case of emergency</b></p>
        <v-row>
            <v-col
            cols="12"
            md="4"
            >
            <v-text-field
                v-model="user.ice"
                label="Contact person"
                required
            ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="4"
            >
            <v-text-field
                v-model="user.icenumber"
                label="Phone number"
                required
            ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col
            cols="12"
            md="4"
            >
            <v-text-field
                v-model="user.allergies"
                label="Allergies"
                required
            ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="4"
            >
            <v-text-field
                v-model="user.medicines"
                label="Medicine use"
                required
            ></v-text-field>
            </v-col>
        </v-row>
        <v-btn color="accent" class="primary--text" @click="update"><b>Save</b></v-btn>
    </div>
</template>

<style scoped>

</style>

<script>
import { db, auth } from '@/firebase'

export default {
    name: 'Personal',
    components: {
    },
    data() {
        return {
            user: {},
            date: null,
            menu: false,
        }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const vm = this;
            var docRef = db.collection("users").doc(auth.currentUser.uid);

            await docRef.get().then(function(doc) {
                if (doc.exists) {
                    vm.user = (doc.data())
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        },
        update() {
            var idRef = db.collection("users").doc(auth.currentUser.uid);
            const vm = this

            return idRef.update(vm.user)
            .then(function() {
                console.log("Document successfully updated!");
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        },
        save (date) {
            this.$refs.menu.save(date)
        },
    }
}
</script>