<template>
    <v-container class="container mx-auto">
        <div class="input">
            <h1>Title</h1>
        </div>
        <div class="input">
            <h2>Personal</h2>
        </div>
        <div class="input">
            <h2>Personal</h2>
        </div>
    </v-container>
</template>

<style scoped>

.container {
    max-width: 500px;
    border: red 1px solid;  
}

.input {
    border: red solid 1px;
}

</style>

<script>
export default {
    
}
</script>