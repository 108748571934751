<template>
    <v-container>
        <h1 class="pb-5">Team</h1>
        <v-divider class="pb-10"></v-divider>
        <v-card>
            <template>
            <v-card-title
            class="primary--text"
            ><b>
            Team members</b>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                color="primary"
            ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="employees"
                :items-per-page="5"
                :loading="isLoading"
                :search="search"
                sort-by="firstName"
                @click:row="memberPage"
            >
                <template v-slot:item.image="{ item }">
                    <v-avatar class="my-3" v-if="item.image">
                        <img :src="item.image" :alt="item.name" height="100px"></img>
                    </v-avatar>
                </template>
            </v-data-table>
            </template>
        </v-card>
    </v-container>
</template>

<style scoped>

</style>

<script>
import { db, auth } from '../firebase'
import { mapState } from 'vuex'
import firebase from "firebase";

export default {
    data () {
        return {
            isLoading: true,
            dialog: false,
            departments: ['Consultancy & Services','Management','Marketing','Product','Sales'],
            search: '',
            uid: '',
            employees: [],
            headers: [
                { text: "", value: "image", sortable: false },
                {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'firstName',
                },
                { text: 'Job title', value: 'jobtitle' },
                { text: 'Department', value: 'department' },
                { text: 'Phone', value: 'phone' },
                { text: 'Email', value: 'email' },
            ]
        }
    },
    computed: {
        ...mapState(['userProfile']),
    },
    created() {
        this.fetchData()
        this.uid = this.userProfile.uid
    },
    methods: {
        async fetchData() {
        const vm = this;
        this.employees = [];

        if (await this.checkBoss()) {
            console.log('boss');
            
            db.collection("users").where("company", "==", await this.getCompany())
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.employees.push(doc.data())
                    vm.isLoading = false
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
        } else {
            console.log('member')
            db.collection("users").where("company", "==", await this.getCompany())
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    vm.employees.push(doc.data())
                    vm.isLoading = false
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
        }
        },
        checkBoss() {
        return new Promise(resolve => {
            const vm = this
            db.collection('users').doc(auth.currentUser.uid)
            .get().then(function(doc) {
                if (doc.exists) {
                resolve(doc.data().boss)
                } else {
                    console.log("No such document!");
                }
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
        })
        },
        getCompany() {
        return new Promise(resolve => {
            const vm = this
            db.collection('users').doc(auth.currentUser.uid)
            .get().then(function(doc) {
                if (doc.exists) {
                resolve(doc.data().company)
                } else {
                    console.log("No such document!");
                }
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
        })
        },
        memberPage(item){
            this.$router.push(`/team/${item.uid}`)
        },
    }
}
</script>