<template>
  <v-container>
    <h1 class="pb-5">Dashboard</h1>
    <v-divider class="pb-10"></v-divider>
    <h3>Hi Team!</h3>
    <br>
    <p>We've set up this simple tool to request reimbursements. <br> On the left you can see 'reimbursements' and request one,
      see which ones are approved and/or delete one. 
    </p>
    <p>If this is your first time logging in, please fill in your details on the 'account' page.</p>
    <p>Also, let me know if you can think of new features for our internal dashboard below.</p>
    <p>Thanks!</p>
    <p>Max</p>
    <v-divider class="my-5"></v-divider>
    <Ideas />
  </v-container>
</template>

<style scoped>

.shortcuts {
  max-width: 300px;
}
.cards {
  display: flex;
}
</style>

<script>
import Ideas from '../components/Ideas'

export default {
  name: 'Dashboard',
  components: {
    Ideas
  },
  data() {
    return {
      items: [
      {icon: 'mdi-arrow-right-bold', text: 'Brainpeek', route: 'https://brainpeek.braingineers.com' },
      {icon: 'mdi-arrow-right-bold', text: 'Admin', route: 'https://admin.braingineers.com' },
      {icon: 'mdi-arrow-right-bold', text: 'TAPP', route: 'https://tapp.braingineers.com' },
    ],
    }
  },
  methods: {
    goTo(route) {
      window.open(route, "_blank"); 
    }
  }
}
</script>
