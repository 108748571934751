<template>
    <v-container>
        <h1 class="pb-5">Account</h1>
        <v-divider class="pb-10"></v-divider>
        <div class="name">
            <v-avatar v-if="user.image" height="70" width="70" class="mr-4">
                <img :src="user.image" alt="">
            </v-avatar>
            

            <div>
                <h2>{{userProfile.firstName}} {{userProfile.lastName}}</h2>
                <h4>{{userProfile.jobtitle}}</h4>
            </div>
        </div>
        <v-tabs
        background-color="transparent"
        v-model="tab"
        class="mt-5"
        >
            <v-tab>Account</v-tab>
            <v-tab>Personal info</v-tab>
        </v-tabs>
        <v-divider class="pb-5"></v-divider>
        <div class="mt-5" v-if="tab === 0">
            <p><b>Account details</b></p>
            <v-row>
                <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                    v-model="user.firstName"
                    label="First name"
                    required
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="5"
                >
                <v-text-field
                    v-model="user.lastName"
                    label="Last name"
                    required
                ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                    v-model="user.jobtitle"
                    label="Job title"
                    required
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="5"
                >
                <v-select
                :items="departments"
                label="Department"
                v-model="user.department"
                ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                    v-model="user.phone"
                    label="Phone number"
                    required
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="5"
                >
                <v-text-field
                    v-model="user.email"
                    label="Email"
                    required
                    readonly
                ></v-text-field>
                </v-col>
            </v-row>
            
            <v-btn
            color="accent"
            class="primary--text"
            @click="update"
            ><b>Save</b></v-btn>
        </div>

        <Personal v-if="tab === 1" class="mt-5"/>

        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="secondary"
        >
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </v-container>
</template>

<style scoped>
.name {
    display: flex;
}

.contract {
    margin-top: 25px;
}

</style>

<script>
import Personal from '../components/Account/Personal'
import { db, auth } from '../firebase'
import { mapState } from 'vuex'
import firebase from "firebase";

export default {
    components: {
        Personal
    },
    data() {
        return {
            user: {},
            tab: '',
            departments: ['Consultancy & Services','Management','Marketing','Product','Sales'],
            snackbar: false,
            text: 'Data updated!',
            timeout: 3000,
            dialog: false,
            image: [],
        }
    },
    computed: {
        ...mapState(['userProfile']),
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const vm = this;
            var docRef = db.collection("users").doc(auth.currentUser.uid);

            await docRef.get().then(function(doc) {
                if (doc.exists) {
                    vm.user = (doc.data())
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        },
        update() {
            var idRef = db.collection("users").doc(auth.currentUser.uid);
            const vm = this

            return idRef.update(vm.user)
            .then(function() {
                console.log("Document successfully updated!");
                vm.snackbar = true
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        },
        upload() {
            const uid = auth.currentUser.uid;
            const docRef = db.collection('reimbursements')
            var d = new Date();
            var storageRef = firebase.storage().ref();
            var collectionRef = storageRef.child('avatars');
            var avatar = collectionRef.child(String(uid) + '.jpg');
            var file = this.image
            const vm = this
            
            avatar.put(file)
            .then(function(snapshot) {
            snapshot.ref.getDownloadURL()
                .then(function(downloadURL) {
                console.log('File available at', downloadURL);
                db.collection("users").doc(String(uid))
                .update({
                image: downloadURL
                })
                })
            })
            .then(function() {
                vm.dialog = false
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
        },
    }
}
</script>