<template>
    <v-container>
        <div class="head">
            <h1>{{user.firstName}} {{user.lastName}}</h1>
            <h4>{{user.jobtitle}}</h4>
            <v-chip
            class="ma-2"
            color="primary"
            v-if="user.boss"
            >
            Admin
            </v-chip><br>
            <v-avatar width="100px" height="100px" class="my-5">
                <img :src="user.image" alt="">
            </v-avatar>
        </div>
        <div class="information">
            <div class="account">
                <p><b>Home address</b></p>
                <p>{{ user.address }} <br> {{ user.postal }} <br> {{ user.city }}</p>
                <br>
                <p><b>Birthday</b> &#127874;</p>
                <p>{{ user.birthday }}</p>
            </div>
            <div class="ice">
                <p><b>In case of emergency</b></p>
                <p>{{user.ice}} <br> {{ user.icenumber }}</p>
                <p><b>Allergies</b></p>
                <p>{{user.allergies }}</p>
                <p><b>Medicines</b></p>
                <p>{{ user.medicines }}</p>
            </div>
        </div>
    </v-container>
</template>

<style scoped>

.information {
    display: flex;
    gap: 50px;
}

</style>

<script>
import { db, auth } from '../firebase'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            user: {}
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        ...mapState(['userProfile']),
    },
    methods: {
        async fetchData() {
            const id = this.$route.params.id;
            const vm = this;
            var docRef = db.collection("users").doc(id);

            await docRef.get().then(function(doc) {
                if (doc.exists) {
                    vm.user = (doc.data())
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        },
    }
}
</script>