<template>
  <v-app>

    <Nav
    v-if="!$route.meta.noNav"
    style="height: 100%"
    ></Nav>
    <v-main>
      
      <router-view class="ma-10"/>
    </v-main>
  </v-app>
</template>

<script>
import Nav from './components/Nav.vue';
import Bar from './components/Bar.vue'

export default {
  name: 'App',
  components: {
    Nav, Bar
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>

* {
  background-color: #ebebef;
  color: #0B0B3D;
  font-family: 'Poppins', sans-serif;
}

</style>
