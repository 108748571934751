import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'mdi', 
    },
    theme: {
      themes: {
        light: {
          primary: '#0A0D3D',
          secondary: '#202357',
          accent: '#00DDAB',
          error: '#FF14A9',
          info: '#F1C40F',
          success: '#00DEAB',
          warning: '#0931F0'
        },
      },
    },
  })