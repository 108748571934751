import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyDymnNjS20s90Xu29R_6lLlFrhOsr113gg",
    authDomain: "cortexp-bdcbc.firebaseapp.com",
    databaseURL: "https://cortexp-bdcbc.firebaseio.com",
    projectId: "cortexp-bdcbc",
    storageBucket: "cortexp-bdcbc.appspot.com",
    messagingSenderId: "1054110355860",
    appId: "1:1054110355860:web:9c8b8e65c983ff93df3f81"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection
}