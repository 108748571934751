<template>
  <v-container>
      <h1 class="pb-5">Reimbursements of <span v-if="userProfile.boss">your team</span><span v-if="!userProfile.boss">{{ userProfile.firstName }}</span></h1>
      <v-divider class="pb-10"></v-divider>
      <v-row class="request">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              class="primary--text"
              dark
              v-bind="attrs"
              v-on="on"
            ><b>
              New request</b>
            </v-btn>
          </template>
          <v-card>
            <v-card-title
            class="accent py-5 primary--text elevation-2"
            >
              <span class="headline"><b>Request reimbursement</b></span>
            </v-card-title>
            <v-card-text class="mt-3">
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="colpad"
                  >
                    <v-text-field
                      label="Amount"
                      required
                      v-model="input.amount"
                      prepend-icon="mdi-currency-eur"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" class="colpad">
                    <v-select
                      label="Category"
                      required
                      :items="categories"
                      v-model="input.category"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="colpad">
                    <v-text-field
                      label="Description"
                      required
                      v-model="input.description"
                      prepend-icon="mdi-pencil"
                    ></v-text-field>
                    <v-file-input
                      accept="image/*,application/pdf"
                      label="Upload receipt"
                      v-model="input.receipt"
                    ></v-file-input>
                  </v-col>
                  
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false ; input = {}"
              >
                Close
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-card>
        <v-card-title
        class="primary--text"
        ><b>
        Reimbursements</b>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            color="primary"
        ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="reimbursements"
          sort-by="timestamp"
          :search="search"
          :sort-desc="[true]"
        >
          <template v-slot:item.timestamp="{ item }">
            <span>
              {{ getTime(item.timestamp)}}
            </span>
          </template>
          
          <template v-slot:item.approved="{ item }">
            <v-simple-checkbox
              v-model="item.approved"
              disabled
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
            @click="getURL(item.id)"
            class="mr-5"
            >
              mdi-eye
            </v-icon>
            <v-icon
            @click="deleteItem(item)"
            class="mr-5"
            v-if="!item.approved"
            >
              mdi-delete
            </v-icon>
            <v-icon
            @click="approve(item.id)"
            class="mr-5"
            v-if="!item.approved && userProfile.boss"
            >
              mdi-check
            </v-icon>
          </template>
        </v-data-table>
    </v-card>
    <v-row>
      
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="secondary"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style scoped>

.request {
  margin-bottom: 25px;
  margin-left: 0px;
}

.colpad {
  padding-top: 0;
  padding-bottom: 0;
}

</style>

<script>
import { db, auth } from '../firebase'
import { mapState } from 'vuex'
import firebase from "firebase";
import moment from 'moment';

export default {
  data () {
    return {
      search: '',
      dialog: false,
      disabled: true,
      newId: null,
      headers: [
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'description',
        },
        { text: 'Category', value: 'category' },
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'timestamp' },
        { text: 'Approved', value: 'approved' },
        { text: 'Receipt // Delete', value: 'actions', sortable: false },
      ],
      reimbursements: [],
      categories: [
        'Advertising','Clothing','Events','Food & drinks','Laptop', 'Other','Phone','Software','Travel'
      ],
      input: {
        amount: null,
        category: '',
        description: '',
        receipt: [],
      },
      snackbar: false,
      text: 'Data updated!',
      timeout: 3000,
      img: '',
    }
  },
  computed: {
    ...mapState(['userProfile']),
  },
  created() {
    this.fetchData()
    this.uid = this.userProfile.uid
  },
  methods: {
    async save () {
        const uid = auth.currentUser.uid;
        const vm = this
        const idRef = db.collection("company").doc(await this.getCompany())

        idRef.get()
        .then(function(doc) {
            if (doc.exists) {
                vm.newId = doc.data().reimbursements;
                console.log(vm.newId)
            } else {
                console.log("No such document!");
            }
        })
        .then(function(){
          vm.upload();
          vm.input = {}
        })
        .catch(function(error) {
            console.log("Error getting document:", error);
        });
    },
    upload() {
      const uid = auth.currentUser.uid;
      const docRef = db.collection('reimbursements')
      var d = new Date();
      var storageRef = firebase.storage().ref();
      var collectionRef = storageRef.child('reimbursements');
      var receipt = collectionRef.child(String(this.newId));
      var file = this.input.receipt
      const vm = this
      
      docRef.doc(String(this.newId)).set({
          amount: this.input.amount,
          category: this.input.category,
          description: this.input.description,
          id: String(this.newId),
          userID: this.userProfile.uid,
          name: this.userProfile.firstName,
          approved: false,
          receipt: '',
          timestamp: new Date(),
      })
      .then(function() {
        receipt.put(file)
        .then(function(snapshot) {
          snapshot.ref.getDownloadURL()
            .then(function(downloadURL) {
            console.log('File available at', downloadURL);
            db.collection("reimbursements").doc(String(vm.newId))
            .update({
              receipt: downloadURL
            })
            })
          })
      })
      .then(function() {
        vm.dialog = false;
        vm.snackbar = true;
        vm.fetchData();
      })
      .then(async function(){
        const companyRef = db.collection("company").doc(await vm.getCompany())
        const increment = firebase.firestore.FieldValue.increment(1);
        companyRef.update({reimbursements: increment})
        console.log('incremented!')
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    },
    async fetchData() {
      const vm = this;
      this.reimbursements = [];

      if (await this.checkBoss()) {
        console.log('boss');
        vm.switch();
        db.collection("reimbursements")
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                vm.reimbursements.push(doc.data())
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
      } else {
        console.log('member')
        
        db.collection("reimbursements").where("userID", "==", `${auth.currentUser.uid}`)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              vm.reimbursements.push(doc.data())
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
      }
      
    },
    delete(id) {
      const vm = this
      db.collection('reimbursements').doc(id).delete()
      .then(function() {
          console.log("Document successfully deleted!");
          vm.fetchData();
          vm.snackbar = true;
      }).catch(function(error) {
          console.error("Error removing document: ", error);
      });
    },
    deleteItem (item) {
      console.log(item.id)
      confirm('Are you sure you want to delete this item?') && this.delete(String(item.id))
    },
    checkBoss() {
      return new Promise(resolve => {
        const vm = this
        db.collection('users').doc(auth.currentUser.uid)
        .get().then(function(doc) {
            if (doc.exists) {
              resolve(doc.data().boss)
            } else {
                console.log("No such document!");
            }
        })
        .catch(function(error) {
            console.log("Error getting document:", error);
        });
      })
    },
    getCompany() {
    return new Promise(resolve => {
        const vm = this
        db.collection('users').doc(auth.currentUser.uid)
        .get().then(function(doc) {
            if (doc.exists) {
            resolve(doc.data().company)
            } else {
                console.log("No such document!");
            }
        })
        .catch(function(error) {
            console.log("Error getting document:", error);
        });
    })
    },
    switch() {
      this.headers = [
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'description',
        },
        { text: 'Requester', value: 'name' },
        { text: 'Category', value: 'category' },
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'timestamp' },
        { text: 'Approved', value: 'approved' },
        { text: 'Receipt // Delete // Approve', value: 'actions', sortable: false },
      ],
      this.disabled = false;
    },
    update() {
      //not necessary function anymore, but maybe usefull for later
      var i;
      for (i = 0; i < this.reimbursements.length; i++) {
        let idRef = db.collection("reimbursements").doc(String(this.reimbursements[i].id))
        idRef.update({
            approved: this.reimbursements[i].approved
        })
      }
      this.snackbar = true;
    },
    getURL(id) {
      firebase
      .storage()
      .ref('reimbursements/' + id)
      .getDownloadURL()
      .then(imgUrl => {
        window.open(imgUrl, "_blank"); 
      });
    },
    async approve(id) {
      let idRef = db.collection("reimbursements").doc(id)
      await idRef.update({
        approved: true
      })
      this.addApproved(id)
      this.fetchData()
    },
    addApproved(id) {
      var docRef = db.collection("reimbursements").doc(id);
      const vm = this
      docRef.get().then(async function(doc) {
          if (doc.exists) {
            let item = doc.data()
            db.collection("company").doc(await vm.getCompany()).collection('approved').doc(id).set(item)
            .then(function() {
                console.log("Document copied to approved!");
                vm.snackbar = true;
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });

          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    getTime(time) {
      return moment(time.toDate()).format("DD-MM-YYYY")
    },
  },
}
</script>

