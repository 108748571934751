<template>
    <div class="center">
        <div class="text">
            <v-img src="@/assets/imgs/logo.svg" style="width: 50px;"></v-img>
            <br>
            <h1>Sign up</h1>
            <p>Please signup below.</p>
            <router-link to="/signin">Already got an account?</router-link>
        </div>
        <v-divider class="divider"></v-divider>
        <v-form
        >
            <v-row class="rows">
                <v-col
                cols="6"
                md="6"
                class="remove"
                >
                    <v-text-field
                    v-model="fname"
                    label="First name"
                    type="text"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                cols="6"
                md="6"
                class="remove"
                >
                    <v-text-field
                    v-model="lname"
                    label="Last name"
                    type="text"
                    required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row  class="rows">
                <v-col
                cols="12"
                md="12"
                class="remove"
                >
                    <v-text-field
                    v-model="companyID"
                    label="Company ID"
                    type="text"
                    required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                cols="12"
                md="12"
                class="remove"
                >
                <v-text-field
                    v-model="email"
                    label="Email"
                    type="email"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    label="Password"
                    type="password"
                    required
                ></v-text-field>
                <v-btn 
                :loading="isLoading"
                :disabled="isLoading"
                @click="isLoading = true ; signup()" 
                color="primary"
                >Go!</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import firebase from "firebase";

export default {
    data() {
        return {
            email: '',
            password: '',
            fname: '',
            lname: '',
            isLoading: false,
            companyID: '',
        }
    },
    methods: {
        signup() {
            this.$store.dispatch('signup', {
                email: this.email,
                password: this.password,
                fname: this.fname,
                lname: this.lname,
                company: this.companyID,
                boss: false,
            })
        }
    },
}
</script>


<style scoped>

.center {
    max-width: 500px;
    margin: 0 auto;
}

.divider {
    margin-top: 10px;
    margin-bottom: 30px;
}

.remove {
    padding-bottom: 0;
    padding-top: 0;
}

</style>