<template>
    <v-navigation-drawer
    left
    absolute
    app
    permanent
    expand-on-hover
    color="primary"
    dark
    class="drawer"
    >
        <v-list dense>
        <v-list-item class="px-2">
            <v-list-item-avatar tile>
                <v-img src="@/assets/imgs/logo.svg"
                ></v-img>
            </v-list-item-avatar>
            <v-list-item-title>
                <h2><b>Teams</b></h2>
            </v-list-item-title>
        
        </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list dense>
            <v-list-item class="px-2">
            <v-list-item-avatar>
                <v-img v-bind:src="image"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
            <v-list-item-title>{{ userProfile.firstName }}</v-list-item-title>
            <v-list-item-subtitle>Welcome!</v-list-item-subtitle>
            </v-list-item-content>

            
        </v-list-item>

        <v-divider></v-divider>
        
        <v-list-item
        v-for="item in items"
        :key="item.text"
        link
        :to="item.route"
        >
            <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                {{ item.text }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
       
        </v-list>
        
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-list dense>
           
            <v-list-item @click="toAccount">
                <v-list-item-action>
                    <v-icon>mdi-account</v-icon>
                </v-list-item-action>
                
                <v-list-item-content>
                    <v-list-item-title>
                        Account
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            
            <v-list-item @click="logout">
                <v-list-item-action>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Signout
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import firebase from "firebase";
import { mapState } from 'vuex'
import router from '../router/index';
import { db, auth } from '../firebase'

export default {
    data() {
        return {
            items: [
                { icon: 'mdi-currency-eur',text: 'Reimbursements', route: '/reimbursements' },
                { icon: 'mdi-account-multiple',text: 'Team', route: '/team'},
            ],
            image: ''
        }
    },
    computed: {
        ...mapState(['userProfile'])
    },
    created() {
        this.getAvatar()
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        toAccount() {
            router.replace('/account')
        },
        getAvatar() {
            var avatarRef = db.collection("users").doc(auth.currentUser.uid);
            const vm = this
            avatarRef.get().then(function(doc) {
                if (doc.exists) {
                    vm.image = doc.data().image
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });

        }
    },
}
</script>

<style scoped>

.logout {
    position: absolute;
    bottom: 0;
}

</style>