<template>
    <div class="center">
        <div class="text">
            <v-img src="@/assets/imgs/logo.svg" style="width: 50px;"></v-img>
            <br>
            <h1>Welcome to Teams!</h1>
            <p>Please login with your @braingineers.com email.</p>
        </div>
        <v-btn
        color="primary"
        class="ma-2 white--text"
        fab
        @click="google"
        >
        <v-icon dark>
            mdi-google
        </v-icon>
        </v-btn>
    </div>
</template>

<style scoped>

.center {
    max-width: 500px;
    margin: 0 auto;
}

.google {
    max-width: 200px;
    border: red 1px solid;
    margin-top: 40px;
    background-color: white;
    border-radius: 5px;
}

.google img {
    width: 100%;
    padding: 0 0;
}

</style>


<script>
import firebase from "firebase";
import * as fb from '../firebase'

export default {
    data() {
        return {
        }
    },
    methods: {
        async google() {
            const provider = new firebase.auth.GoogleAuthProvider();
            const vm = this
            await firebase.auth().signInWithPopup(provider)
            .then(function(result) {
                var user = result.user
                if (result.additionalUserInfo.isNewUser) {
                    console.log('new user')
                    const name = result.user.displayName;
                    const firstName = name.split(' ')
                    fb.usersCollection.doc(result.user.uid).set({
                        uid: result.user.uid,
                        email: result.user.email,
                        company: vm.checkDomain(result.user.email),
                        image: result.user.photoURL,
                        firstName: firstName[0],
                    })
                } else {
                    console.log('existing user')
                }
                vm.$store.dispatch('fetchUserProfile', user)
            })
        },
        checkDomain(email) {
            var n = email.endsWith("@braingineers.com");
            if (n) {
                return 'vzm9nZRWZxf5NlV3IBMG'
            } else {
                return null
            }
        },
    },
}
</script>


