<template>
    <v-app-bar
    height="72"
    color="secondary"
    class="white--text"
    flat
    >
        <v-toolbar-title><b>Teams</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="white" @click="goTo()">
            <v-icon>mdi-heart</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import router from '../router/index';

export default {
    name: 'Bar',
    computed: {
        ...mapState(['userProfile'])
    },
    methods: {
        goTo() {
            router.replace('/kudos')
        }
    }
}
</script>