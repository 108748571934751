<template>
    <v-container>
        <h1>Kudos!</h1>
        <div class="give">
            <v-card
            class="px-10 card mb-5 pt-1"
            >
                <v-row align="center">
                    <v-col
                    cols="12"
                    md="3"
                    >
                    <v-select
                    placeholder="Pick your colleague"
                    :items="members"
                    item-text="firstName"
                    item-value="uid"
                    v-model="member"
                    ></v-select>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    >
                    <v-text-field
                    placeholder="Add a note"
                    v-model="note"
                    ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                    <v-btn block color="success" class="primary--text mb-4" @click="save"><b>Give</b></v-btn>
                    </v-col>
                    
                </v-row>
                
            </v-card>
            
        </div>

        <h3>Received kudos</h3>
        <div class="kudo"
        v-for="(item, index) in kudos"
        :key="index"
        v-bind:data-index="index"
        >
            <div class="from">
                <v-avatar>
                    <img :src="item.image" alt="">
                </v-avatar>
                <p>{{ item.from }}</p>
            </div>
            <div class="note">
                <p>"{{ item.note }}"</p>
            </div>
        </div>

        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="success"
        >
        <b>{{ text }} </b>
        
        <v-icon>mdi-heart</v-icon>

        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </v-container>
</template>

<style scoped>

h1 {
    margin-bottom: 35px;
}

h3 {
    margin-bottom: 15px;
}

.card {
    max-width: 800px;
}

.kudo {
    background-color: white;
    border-radius: 6px;
    padding: 20px 20px;
    display: flex;
    max-width: 800px;
    margin-bottom: 10px;
}

.from {
    text-align: center;
    padding-right: 40px;
    padding-left: 20px; 
    border-right: 1.5px solid #ebebef;
}

.from p {
    margin-bottom: 0;
}

.note {
    padding-left: 20px;
}

</style>

<script>
import { db, auth } from '../firebase'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            note: '',
            member: '',
            members: '',
            kudos: [],
            snackbar: false,
            text: 'Your kudos have been sent!',
            timeout: 2000,
        }
    },
    created() {
        this.fetchMembers();
        this.fetchKudos();
    },
    computed: {
        ...mapState(['userProfile']),
    },
    methods: {
        async fetchMembers() {
        const vm = this;
        this.members = [];

        db.collection("users").where("company", "==", await this.getCompany())
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                vm.members.push(doc.data())
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
        },
        async fetchKudos() {
        const vm = this;
        this.kudos = [];

        db.collection("kudos").where("for", "==", auth.currentUser.uid)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                vm.kudos.push(doc.data())
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
        },
        getCompany() {
        return new Promise(resolve => {
            const vm = this
            db.collection('users').doc(auth.currentUser.uid)
            .get().then(function(doc) {
                if (doc.exists) {
                resolve(doc.data().company)
                } else {
                    console.log("No such document!");
                }
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
        })
        },
        async save () {
            const uid = auth.currentUser.uid;
            const vm = this

            db.collection("kudos").add({
                from: vm.userProfile.firstName,
                note: vm.note,
                timestamp: new Date(),
                uid: uid,
                for: vm.member,
                image: vm.userProfile.image,
            })
            .then(function() {
                console.log("Document successfully written!");
                vm.snackbar = true;
                vm.member = '';
                vm.note = '';
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });


        },
    }
}
</script>